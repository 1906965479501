import { LOAD_STATE } from '@/shared/types'
import type { HotNewsRankResponse } from '@/server/types'
import { getHotNewsRankApi } from '@/server'

export function logicHotNewsRank() {
  const labelList = ['日', '周', '月']
  const codeList = ['DAY', 'WEEK', 'MONTH'] as const
  const active = ref<number>(2)

  const data = reactive(labelList.map(_ => ({
    loadState: LOAD_STATE.INIT,
    list: [] as HotNewsRankResponse[],
  })))

  async function getRankData() {
    const res = await useLoadState({
      state: (state) => { data[active.value].loadState = state },
      key: `热门资讯排行榜-${codeList[active.value]}`,
      onlyClient: true,
      fn: () => getHotNewsRankApi(codeList[active.value]),
    })

    if (res && res.data)
      data[active.value].list = res.data
  }

  useActivatedWatch(() => [
    watch(active, () => {
      if (data[active.value].list.length === 0)
        getRankData()
    }),
  ])
  return {
    data,
    labelList,
    active,
    getRankData,
  }
}
