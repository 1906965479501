<script setup lang='ts'>
import { APP_ROUTER } from '@qctsw/common'
import { logicHotNewsRank } from '@/logic/rank/hotNews'

const props = withDefaults(defineProps<{
  embedded?: boolean
}>(), {
  embedded: true,
})

const {
  labelList,
  active,
  data,
  getRankData,
} = logicHotNewsRank()

await getRankData()
</script>

<template>
  <n-card
    title="热门资讯" :embedded="props.embedded" size="small"
    class="rounded-md"
    header-style="--n-title-font-weight: bold;"
  >
    <template #header-extra>
      <BasicSwitchTab v-model:active="active" :key-list="labelList" />
    </template>
    <UtilLoadState :state="data[active].loadState" :reload-handler="getRankData">
      <template v-if="data[active].list.length">
        <NuxtLink
          v-for="(v, i) in data[active].list" :key="i"
          class="mb-sm text-3.5 flex items-center last:mb-0"
          :to="APP_ROUTER.newsDetail(v.id)"
        >
          <span class="rank mr-2.5" :class="`rank__${i}`">{{ i + 1 }}</span>
          <span class="line-clamp-1 text-4 hover:text-orange-6">
            {{ v.title }}
          </span>
        </NuxtLink>
      </template>
      <BasicEmpty v-else />
    </UtilLoadState>
  </n-card>
</template>

<style lang='scss' scoped>
@import url(@/assets/styles/rank.scss);
</style>
