import type {
  AllMediaResponse,
  AuthorCorrelationResponse,
  AuthorDetailResponse,
  CctvNewsRankResponse,
  HomeBannerData,
  HomeNewsBannerData,
  HomeNewsPageResponse,
  HotNewsRankResponse,
  MediaDetailResponse,
  MediaNewsRankResponse,
  NewsColumnPageParams,
  NewsColumnPageResponse,
  NewsColumnResponse,
  NewsDetailResponse,
  NewsRecommendColumnResponse,
} from './types'
import type { FetchResponseType, PaginationParams, PaginationResponse } from '@/server/request/types'
import { useApiFetch } from '@/server/request'
import type { COOP_MEDIA_TYPE_CODE } from '@/shared/constants'

/**
 * 首页banner数据
 * @returns FetchResponseType<HomeBannerData>
 */
export async function getHomeBannerDataApi() {
  return useApiFetch<FetchResponseType<HomeBannerData>>('/webportal/base/home/showBannerArticleLs', {
    method: 'GET',
  })
}
/**
 * 资讯首页banner数据
 * @returns FetchResponseType<Omit<HomeBannerData, 'complain'>>
 */
export async function getNewsBannerDataApi() {
  return useApiFetch<FetchResponseType<Omit<HomeBannerData, 'complain'>>>('/webportal/base/article/showBannerArticle', {
    method: 'GET',
  })
}
/**
 * 首页下方资讯banner数据
 */
export async function getHomeNewsBannerDataApi() {
  return useApiFetch<FetchResponseType<HomeNewsBannerData[]>>('/webportal/base/home/articleGistLs', {
    method: 'GET',
  })
}

/**
 * 首页资讯分页数据
 */
export async function getNewsPageApi(params: PaginationParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<HomeNewsPageResponse[]>>>(`/webportal/base/home/articleGistPage`, {
    method: 'GET',
    params,
  })
}

/**
 * 资讯栏目分页数据
 */
export async function getNewsColumnPageApi(params: NewsColumnPageParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<NewsColumnPageResponse[]>>>(`/webportal/base/article/page`, {
    method: 'GET',
    params,
  })
}

/**
 * 资讯首页栏目数据
 */
export async function getNewsRecommendColumnDataApi() {
  return useApiFetch<FetchResponseType<NewsRecommendColumnResponse[]>>('/webportal/base/article/columnRecom', {
    method: 'GET',
  })
}
/**
 * 资讯全部栏目数据
 */
export async function getNewsAllColumnDataApi() {
  return useApiFetch<FetchResponseType<NewsColumnResponse[]>>('/webportal/base/home/articleColumnLs', {
    method: 'GET',
  })
}
/**
 * 全部媒体
 */
export async function getAllMediaApi() {
  return useApiFetch<FetchResponseType<Record<keyof typeof COOP_MEDIA_TYPE_CODE, AllMediaResponse>>>('webportal/base/coop/media/allCoopArticle', {
    method: 'GET',
  })
}

/**
 * 媒体详情
 */
export async function getMediaDetailApi(id: string | number) {
  return useApiFetch<FetchResponseType<MediaDetailResponse>>(`/webportal/base/coop/media/detail/${id}`, {
    method: 'GET',
  })
}

/**
 * 文章详情
 */
export async function getNewsDetailApi(id: string | number) {
  return useApiFetch<FetchResponseType<NewsDetailResponse>>(`/webportal/base/article/detail/${id}`, {
    method: 'GET',
  })
}

/**
 * 作者详情
 */
export async function getAuthorDetailApi(id: string | number) {
  return useApiFetch<FetchResponseType<AuthorDetailResponse>>(`/webportal/base/article/author/${id}`, {
    method: 'GET',
  })
}

/* ************************************************************** */
/* ****************************RANK****************************** */
/* ************************************************************** */

/**
 * 热门资讯
 */
export async function getHotNewsRankApi(timeScopeEnum: 'DAY' | 'WEEK' | 'MONTH') {
  return useApiFetch<FetchResponseType<HotNewsRankResponse[]>>('/webportal/base/ranking/article/hot', {
    method: 'GET',
    params: { timeScopeEnum },
  })
}

/**
 * CCTV
 */
export async function getCctvNewsRankApi() {
  return useApiFetch<FetchResponseType<CctvNewsRankResponse>>('/webportal/base/ranking/article/cctv', {
    method: 'GET',
  })
}

/**
 * 媒体资讯
 */
export async function getMediaNewsRankApi(columnId: number) {
  return useApiFetch<FetchResponseType<MediaNewsRankResponse[]>>('/webportal/base/ranking/article/media', {
    method: 'GET',
    params: { columnId },
  })
}

/**
 * 相关资讯
 */
export async function getAuthorCorrelationRankApi() {
  return useApiFetch<FetchResponseType<AuthorCorrelationResponse[]>>('/webportal/base/ranking/bbs/article', {
    method: 'GET',
  })
}
